import { Controller } from "stimulus";

const COOKIE_KEY = "close_landing_authentication_modal";

export default class extends Controller {
  close() {
    this.element.classList.add("hidden");
    document.cookie = `${COOKIE_KEY}=true; max-age=86400`;
  }
}
